// globalStyles.js
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  
  .wallet-adapter-modal-title {
    color: #fff;
  }

  .wallet-multi {
    background: #94F423;
    color: #15143F;

    i {
      display: none;
    }

    &.active {
      background: #6C6B87;
      color: #FFFFFF;
    }
  }

  button {
    transition: 0.25s ease-in-out all;
  }
`;

export default GlobalStyle;
