import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { Container } from "react-bootstrap";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  getLedgerWallet,
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
  getTorusWallet,
} from "@solana/wallet-adapter-wallets";
// import { clusterApiUrl } from "@solana/web3.js";
import { useCallback, useMemo } from "react";
import toast, { Toaster } from "react-hot-toast";
import RaffleHome from "containers/RaffleHome";
import RaffleDetails from "containers/RaffleDetails";
import styled from "styled-components";
import Logo from "./images/logo.png";

import {
  Switch,
  Route,
  Link,
} from "react-router-dom";
import GetWinners from "containers/GetWinners";

import DiscordLogo from "./images/discord.svg";
import TwitterLogo from "./images/twitter.svg";


const App = () => {
  // Can be set to 'devnet', 'testnet', or 'mainnet-beta'
  // const network = WalletAdapterNetwork.Devnet;
  const network = WalletAdapterNetwork.Mainnet;
  // const network = process.env.REACT_APP_RPC_ID
  // You can also provide a custom RPC endpoint
  const endpoint = process.env.REACT_APP_RPC_ID
  // const endpoint =
  //   "https://cold-small-meadow.solana-mainnet.quiknode.pro/4d5ba9470c46ab34821fae34c32664c71ca5c40f/";
  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded
  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getTorusWallet(),
      getLedgerWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    [network]
  );

  const onError = useCallback(
    (error) => {
      toast.error(
        error.message ? `${error.name}: ${error.message}` : error.name, {
        duration: 3000
      });
    },
    []
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} onError={onError} autoConnect>
        <WalletModalProvider>
          <div style={{ alignItems: 'center', display: 'flex', position: "absolute", right: 20, top: 20, zIndex: 5 }} >
            <a href="https://raydium.io/swap/?inputCurrency=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v&outputCurrency=C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6&outputAmount=0&fixed=in" target="_blank" rel="noreferrer" style={{
              color: 'rgb(31,65,112)',
              fontWeight: 500,
              marginRight: 20,
            }}>Buy $T1NY</a>
            <ul style={{ alignItems: 'center', display: 'flex', margin: 0, padding: 0, listStyle: 'none' }}>
              <li style={{ marginRight: 20 }}>
                <a
                  href="https://twitter.com/TinyDogzNFT"
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  <img src={TwitterLogo} alt="Twitter" />
                </a>
              </li>
              <li style={{ marginRight: 20 }}>
                <a
                  href="https://discord.gg/TinyDogzNFT"
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  <img src={DiscordLogo} alt="Discord" />
                </a>
              </li>
            </ul>
            <WalletMultiButton />
          </div>
          <Container>
            <PageWrapper>
              <TopContainer>
                <div />
                <Link to="/" style={{ alignItems: "center", display: "flex" }}>
                  <img
                    src={Logo}
                    height={123}
                    alt={"GenerousRobotsDAO"}
                    className="long-logo"
                  />
                </Link>
                <div />
              </TopContainer>
              <Switch>
                <Route exact path="/" component={RaffleHome} />
                <Route exact path="/raffle/:id" component={RaffleDetails} />
                <Route exact path="/entries/:id" component={GetWinners} />
              </Switch>
              <Footer className="details">
              </Footer>
            </PageWrapper>
          </Container>
        </WalletModalProvider>
        <Toaster position="top-center" reverseOrder={false} />
      </WalletProvider>
    </ConnectionProvider>
  );
};


export default App

const PageWrapper = styled.div`
  min-height: 100vh;
  padding: 44px 0 100px;
  position: relative;
  z-index: 1;
`;

const TopContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0px auto 50px;
  padding: 56px 16px 0px;

  .long-logo {
    @media only screen and (max-width: 768px) {
      height: auto;
      max-width: 300px;
    }
  }
`;

const Footer = styled.div`
  bottom: 40px;
  display: flex;
  justify-content: space-between;
  left: 0;
  /* margin-top: 40px; */
  /* padding-top: 30px; */
  position: absolute;
  right: 0;
  z-index: 1;

  &.details {
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  &.main {
    @media only screen and (min-width: 768px) {
      display: none;
    }
  }

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-left: 8px;
    }
  }

  span {
    color: #fd6b3d;
    font-family: "Work Sans";
    font-size: 14px;
    font-weight: 600;
    margin-right: 16px;
  }

  p {
    font-family: "Work Sans";
    font-size: 14px;
    font-weight: 600;
    margin: 0;

    a {
      color: #fff;
      text-decoration: none;
    }

    img {
      margin-left: 8px;
      position: relative;
      top: -2px;
    }
  }

  @media only screen and (max-width: 768px) {
    align-items: center;
    flex-direction: column;

    p {
      margin-bottom: 20px;
    }
  }
`;
