import {
  Connection,
  PublicKey,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  Token,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";

export const raffleDefaults = [
  {
    raffleObjectId: "627613b28307f7368ddddad4",
    raffleId: "1",
    pricePerTicket: 10,
    receiverWallet: "DPVRWihLSqkZEgCPxAPmzSU341wVv5BJLmjobu5TiNFy",
    tokenDecimal: 9,
    tokenMint: "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
    winners: 5,
    startDate: "2022-05-06T12:00:00+00:00",
    endDate: "2022-05-07T12:00:00+00:00",
    raffleName: "Vincenia 5x WL",
    raffleImage:
      "https://pbs.twimg.com/profile_images/1509923028297465861/T7RrEP7k_400x400.jpg",
    raffleDesc:
      "A gang of 5555 variant outlaws assembled to carry out the wills of Vincent.",
    soldTickets: 3201,
    type: null,
    drawnWinners: [
      {
        claimed: false,
        id: "7Th5z6J9a25TBYiphRNFCukRb3Ej73BG678sMoGB95q6",
      },
      {
        claimed: false,
        id: "BtVkFnNadRMvfDCTRNWSFvgUzKhbdNHL1YGxdk7VSq3K",
      },
      {
        claimed: false,
        id: "5ZxWJhNXMwSA7vvuDuHFxnrd5oUQJABRZrB63YygjaaE",
      },
      {
        claimed: false,
        id: "EhAucfUHkSm5rLzRcnboWsH8rQKsDS5AZcv6sZFiK8Y5",
      },
      {
        claimed: false,
        id: "7oQExPsMi7bQ4mgbCgsJ7A9rnCNX4jRPudo8uqwtRUM9",
      },
    ],
  },
  {
    raffleObjectId: "627613b8aa039aabf3dfdcb4",
    raffleId: "2",
    pricePerTicket: 10,
    receiverWallet: "TR2vumxVKRnXyhjsAM84Z5iy9hBEvRp7BYVRaraSWo7",
    tokenDecimal: 9,
    tokenMint: "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
    winners: 5,
    startDate: "2022-05-07T12:00:00+00:00",
    endDate: "2022-05-08T12:00:00+00:00",
    raffleName: "Trippy Kidz 5x WL",
    raffleImage:
      "https://pbs.twimg.com/profile_images/1520074234923388929/iH-fcNcV_400x400.jpg",
    soldTickets: 2636,
    raffleDesc: "A brand built by the community for the community.",
    type: null,
    drawnWinners: [
      {
        claimed: false,
        id: "EGbCCZU7F4cb2cbTrororbEQ3ovQWXdkckYj9GngHzQT ",
      },
      {
        claimed: false,
        id: "FpV1AqkZGxr9iCUQx5yJU8Fzg2oqXuAbjTcuTzYrqCAN ",
      },
      {
        claimed: false,
        id: "AbjGViv6RNL7dPr2eAB4vxUJUFcYZjP9t7BkLsiBh2Kf ",
      },
      {
        claimed: false,
        id: "BXD7nvsxMzDLyVvHruyPUKtFKq8BsnrhCBqwUteBwTg",
      },
      {
        claimed: false,
        id: "B76DZoiv4rXgoqsZtCd7EM93iVpoHybEx2kmiHrXBCG2",
      },
    ],
  },
  {
    raffleObjectId: "62762dd46394fe49b251e5cb",
    raffleId: "3",
    pricePerTicket: 20,
    receiverWallet: "TR33UtWxwKXUT3J2NRuaYGCdsPmGphRTSnDcexNk3XQ",
    tokenDecimal: 9,
    tokenMint: "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
    winners: 50,
    startDate: "2022-05-07T15:00:00+00:00",
    endDate: "2022-05-12T12:00:00+00:00",
    raffleName: "Zenjin Viperz 50x WL",
    raffleImage:
      "https://pbs.twimg.com/profile_images/1512179260538212358/kDUDDjiy_400x400.jpg",
    raffleDesc:
      "Zenjin Viperz are a collection of 2,000 NFTs on the #Solana Blockchain.",
    soldTickets: 7836,
    type: null,
    drawnWinners: [
      {
        claimed: false,
        id: "BSTewzr9sD1sgdXpyY4NJZf5tYWz6dyL8wB7WTiBT61R",
      },
      {
        claimed: false,
        id: "C8xvSQNJmANM3Cgmd8N8M8sS4XSn8spBkJm88w6gq8A",
      },
      {
        claimed: false,
        id: "BaG3ZkaNohf1qRWhGHCjph6tP8byWV33JkuUvNC5aucP",
      },
      {
        claimed: false,
        id: "2Uz3KZCKem1m78SEGts57n5H4iinbGAgzeDg9vfD6cLU",
      },
      {
        claimed: false,
        id: "56svzZMsC6CjW8sdtUecBk4UDtJ4F3pnEg6Xh2Ywt87z",
      },
      {
        claimed: false,
        id: "GnGzpD74MKFu4cx7x4HPHr4VoxpgizEka1Fg6AB1K7PZ",
      },
      {
        claimed: false,
        id: "9i8P4HmiV5MFtpez2kzryAQ8J5ewc13EivVD1YCkRY3K",
      },
      {
        claimed: false,
        id: "918eHD2R1my5UzRgG51WsCXS9znqvk2EVmDrb9TtGLqA",
      },
      {
        claimed: false,
        id: "AgGaVAzLwKPDVLcaPrip45HRFKmLnCMFMb86ETFXWiJg",
      },
      {
        claimed: false,
        id: "HEV5RvNQsJr5Y1zC2zVar456t76uDfrUAhtHheyQgBUc",
      },
      {
        claimed: false,
        id: "5BSxVKq2TiGKxY1sUcsemPuQzqw21qBK6RswPGn84BQ3",
      },
      {
        claimed: false,
        id: "EP4mxq4R5GjwyNSRZQxs9eBHrybfK7jJWXih1xQMXnDp",
      },
      {
        claimed: false,
        id: "G8XE5E3MiJKDBZ7YYdnmpMzxRAUHrtSsKk8MHrNYAUhe",
      },
      {
        claimed: false,
        id: "5VDN4a8BoFaEvScinGnK71W4D6FerQSZebnc6vDht2BU",
      },
      {
        claimed: false,
        id: "4xTFPeZR2qq3xfT233dBdgwRcpbRmJF8wzbEynyfD38Z",
      },
      {
        claimed: false,
        id: "9RQHkJG7CJcysfQHt22Kj8U3nvS8bTJC5kypAKznop1e",
      },
      {
        claimed: false,
        id: "3X2PYcCYdmX6ERny2FECkgXuG9C6smcAP1mYjdAU4pgh",
      },
      {
        claimed: false,
        id: "7NHGMhhNuWm2Y2N4EKoNt1A1KAYLxAgfCqNLXLdj7mWU",
      },
      {
        claimed: false,
        id: "H1u7ksi8kXTdiUvtUbSgLk1KJapCBw3hVBqfb8SAYNpb",
      },
      {
        claimed: false,
        id: "Eq84K31jxr12pyV7cGhMEBqDCTGo2WwtoSYNQtdayAoK",
      },
      {
        claimed: false,
        id: "hjSsFxY9KAJ23MDsx8Pdujvu7QVBZMrVAXmnuiPqEDo",
      },
      {
        claimed: false,
        id: "CYFzroRiKTv78tq7CBzTyjXMBABgKBQskBYrriC2tZDT",
      },
      {
        claimed: false,
        id: "9313Pj38KNpRJHdMi9oaGifVu32JqPgHGCktnYaiTNAy",
      },
      {
        claimed: false,
        id: "59bJhacCsUdLPBqL4WuM3xzC8JmzrNyR6yQSiG9LxjqQ",
      },
      {
        claimed: false,
        id: "HfxiTzYhHm6cNwEohVsSBJgujiEfmzC8sMBt1Gtijaef",
      },
      {
        claimed: false,
        id: "Sg74ZoPTLFbiUdsnEyUn4V7wdu8ZUdsakkKm2BvHAx4",
      },
      {
        claimed: false,
        id: "HUULLdggsFH6LfrM3hCfQfi7xeKgY4yDxmJuWNutm9qy",
      },
      {
        claimed: false,
        id: "CqLHCLADikUyBNXgBF4mrDTodcZBwdUrcNoAyVDBGTTi",
      },
      {
        claimed: false,
        id: "F5rMicyfXZbMUJcBMvCZGxsBSjg6EgFXPuesBvjFxJ5",
      },
      {
        claimed: false,
        id: "CDwibFQXcnbEEnb1S2CprPaeXpV1Jwzyukf9SZqPwhtC",
      },
      {
        claimed: false,
        id: "FtSLkappCXkM36ZHKpp6gCmyLrYheXKCdo35cmG3D1HC",
      },
      {
        claimed: false,
        id: "7wKhs1AHKuY7v9anyAEwyCdADdtkGWthVvXrzhUugqdc",
      },
      {
        claimed: false,
        id: "D2hZFQiRwCdjXdLvoLRCVTUPRo15B5ddHVY1E1MRMUDu",
      },
      {
        claimed: false,
        id: "Bni3qGQRELhfYpwknn8dZi96rANPQ2shr8UcF73AAzWZ",
      },
      {
        claimed: false,
        id: "Fx9xU65UaCFa4BgXRaxKqZ1BEUu5mQD8V3LRrFHidkhN",
      },
      {
        claimed: false,
        id: "EiVR3LPDKPBcn4DEJ3L4hjccWzSno2izEGFZBrqL1Ais",
      },
      {
        claimed: false,
        id: "8N7C9nr2gJW6RE1HfZErc8PgK6GLrQGnEYpQXn72UCfM",
      },
      {
        claimed: false,
        id: "FMJji5tC1bcde61LzTUqdFfmVxU3J89v8FCZY1uZuw7E",
      },
      {
        claimed: false,
        id: "J2Zde9tCE9Wt8y6aoaG1TaysZmAxnnPt43uoiqDT7AL6",
      },
      {
        claimed: false,
        id: "E8caXkgbGwnvJCwk8EHorD4qaNhWwPFtD2dW5J6yihAf",
      },
      {
        claimed: false,
        id: "AR32tvJ5Qn9b5siyGpeC6bDsZezuESYAPsvLqRuhnkAV",
      },
      {
        claimed: false,
        id: "FThHWS74esZdr9NbpJCRYMqra4Vni27p1bHySkCJ9XHC",
      },
      {
        claimed: false,
        id: "CR6yAYXN1BBtzZ7gz8fZb88JpojcUPZEEHcxRjC9NfSD",
      },
      {
        claimed: false,
        id: "Fz7yvsToGoGa3Aht6ubTpxE5D6yxhWwS6ja5r3B7TkU3",
      },
      {
        claimed: false,
        id: "s88DZANyfq63HxVQpBbyfrKnQ9CvbZtXxvSAqm4K2Nd",
      },
      {
        claimed: false,
        id: "84E5pmgwaYjmBXP5UEatYD6obg9Ca6u8ieCLW1ZFyVKh",
      },
      {
        claimed: false,
        id: "37p7Py3LP4y6r6TSSb3bLcFxeJ67qHKETvjKtoT6aHm3",
      },
      {
        claimed: false,
        id: "DwcxGJmeC1MtGjjiZSpRJu18CK1t13AFBrhxY8yUzbTP",
      },
      {
        claimed: false,
        id: "7tL2NVa2M729edtobMhPDjGyqKdYqvWE9JjiusQSGkFi",
      },
      {
        claimed: false,
        id: "CGAFN352pUMbQsvSJKQKieFMK1z6X9aNk3vX5y6ngEiA",
      },
    ],
  },
  {
    raffleObjectId: "62783947e59275707a18ed1a",
    raffleId: "4",
    pricePerTicket: 50,
    receiverWallet: "TR4CGBqpEfnNTQpBZV7ZxyJtiXPRDkn8jXZQbUcWvNX",
    tokenDecimal: 9,
    tokenMint: "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
    winners: 1,
    startDate: "2022-05-87T15:00:00+00:00",
    endDate: "2022-05-11T12:00:00+00:00",
    raffleName: "Sea Shanties #171",
    raffleImage:
      "https://arweave.net/tQc5BU4Ad5W0KkJ4DQWvUTwv-P8U9ZBdmIaq4mCjocg",
    raffleDesc: "The first Underwater Metaverse on the Solana Blockchain",
    soldTickets: 232,
    type: null,
    drawnWinners: [
      {
        claimed: false,
        id: "2fquRXDqK1YLLC8E7z74CtLevw1ZiTtd6LG3L2bQsZdM",
      },
    ],
  },
  {
    raffleObjectId: "6278476bae8bdf0d3a60744d",
    raffleId: "5",
    pricePerTicket: 20,
    receiverWallet: "TR537EzLNiYTy7bw7o4NoJEirwkh38VH9YKcx5zRCvQ",
    tokenDecimal: 9,
    tokenMint: "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
    winners: 5,
    startDate: "2022-05-87T15:00:00+00:00",
    endDate: "2022-05-10T12:00:00+00:00",
    raffleName: "5x SUTEKI WL",
    raffleImage:
      "https://pbs.twimg.com/media/FSQm780WQAEvb5t?format=jpg&name=large",
    raffleDesc: "Connecting anime/manga creators and publishing their content",
    soldTickets: 1080,
    type: null,
    drawnWinners: [
      {
        claimed: false,
        id: "3rwbhotUybqeUrsh4WRxtbaGEiQvT8AZ5btvFBq7iAUL",
      },
      {
        claimed: false,
        id: "EhAucfUHkSm5rLzRcnboWsH8rQKsDS5AZcv6sZFiK8Y5",
      },
      {
        claimed: false,
        id: "FvGBz3zG72zBV2Lavcixb1Kg4hSNWyJEkJUK1qcf6RUj",
      },
      {
        claimed: false,
        id: "J1T3aMFikKVRU5TGh8K3mkCdw9ctePQoCPt8VpduRRVV",
      },
      {
        claimed: false,
        id: "7P8E8kFEvuEFzsNe3cHczGntRHBtYpL49Q2FYSSdhae7",
      },
    ],
  },
  {
    raffleObjectId: "627a31c5b6c880674381f336",
    raffleId: "6",
    pricePerTicket: 20,
    receiverWallet: "TR6dvRwPwsYszxJLdRxhWokZYRejEHe6ufS7ASPLN6c",
    tokenDecimal: 9,
    tokenMint: "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
    winners: 5,
    startDate: "2022-05-10T12:00:00+00:00",
    endDate: "2022-05-11T13:00:00+00:00",
    raffleName: "5x NekoMata WL",
    raffleImage:
      "https://pbs.twimg.com/media/FSVwySPVIAAjnVP?format=jpg&name=4096x4096",
    raffleDesc: "Forging the bond",
    soldTickets: 314,
    type: null,
    drawnWinners: [
      {
        claimed: false,
        id: "EvLHL3kC36fzXiAXSXrP4H4XPY3MsxcFDGJ61Xb8XP54",
      },
      {
        claimed: false,
        id: "GMRaU1umCjfHcP86i5HihgXNEnT9EMsHS238YjwjX9QB",
      },
      {
        claimed: false,
        id: "BPByvzsvir33XjCZzsU5KNZpfmbp5k9ptYeWoAPvnU9g",
      },
      {
        claimed: false,
        id: "CYFzroRiKTv78tq7CBzTyjXMBABgKBQskBYrriC2tZDT",
      },
      {
        claimed: false,
        id: "BXD7nvsxMzDLyVvHruyPUKtFKq8BsnrhCBqwUteBwTg",
      },
    ],
  },
  {
    raffleObjectId: "627b9600409b7eb47def898d",
    raffleId: "7",
    pricePerTicket: 20,
    receiverWallet: "TR7oifLegu9K2znM9ba3LwkpJ5K1rA9srQdFpG5GVcU",
    tokenDecimal: 9,
    tokenMint: "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
    winners: 5,
    startDate: "2022-05-11T12:00:00+00:00",
    endDate: "2022-05-12T12:00:00+00:00",
    raffleName: "5x Reck Racoon WL",
    raffleImage:
      "https://pbs.twimg.com/media/FSZ4WgqWYAQ_jxF?format=jpg&name=4096x4096",
    raffleDesc: "Reckless Racoons escaped from the club.",
    soldTickets: 271,
    type: null,
    drawnWinners: [
      {
        claimed: false,
        id: "C8B4JX4FYQhLxCrw4WWGZ8SYtSxaQ4pnkpvUBmLTmR8j",
      },
      {
        claimed: false,
        id: "FSVnkCJb1dTDkv4bCw7TRAjDyRJnaq4Aai3SGEcdUJ33",
      },
      {
        claimed: false,
        id: "2eji6aYhKUUXp7qgbSJFhHvkKPMHw9kxARTPHADtdPhZ",
      },
      {
        claimed: false,
        id: "87inEQX4wrhnavyutoL7pbpJ43nj6pRbEGhhXJ2sJRFe",
      },
      {
        claimed: false,
        id: "GQRkRdhptY1Qv3orkqVJgqYAyf9MHvq6GoKSexs3UqVq",
      },
    ],
  },
  {
    raffleObjectId: "627d0dc804c54e483bcbaeec",
    raffleId: "8",
    pricePerTicket: 50,
    receiverWallet: "TR8sAdUcuhubXZATMgBsHBsLwFwAipfMQkEonQdY7cX",
    tokenDecimal: 9,
    tokenMint: "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
    winners: 1,
    startDate: "2022-05-12T12:00:00+00:00",
    endDate: "2022-05-14T12:00:00+00:00",
    raffleName: "DeFi Pirate #3767",
    raffleImage:
      "https://img-cdn.magiceden.dev/rs:fill:640:640:0:0/plain/https://chest.mypinata.cloud/ipfs/QmS5GYVvxm5s144rMqAX2qpfD9KXDQURU5Uj1Jvd23Jq2P/3767.gif",
    raffleDesc: "",
    drawnWinners: [
      {
        claimed: false,
        id: "EjWyUqvBuqFiK7hdCv8Lhe6NCzKtLQT9GfNFKDNQbhHZ",
      },
    ],
    type: null,
  },
  {
    raffleObjectId: "627d0dc41682943105122173",
    raffleId: "9",
    pricePerTicket: 20,
    receiverWallet: "TR9HXbMnTv6Jn4tUgDADcs3Fk9b8oMxzbqgDsiTV18D",
    tokenDecimal: 9,
    tokenMint: "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
    winners: 5,
    startDate: "2022-05-12T12:00:00+00:00",
    endDate: "2022-05-14T12:00:00+00:00",
    raffleName: "5x Oak Paradise WL",
    raffleImage:
      "https://pbs.twimg.com/media/FR7qft3aUAAhkdQ?format=jpg&name=medium",
    raffleDesc: "The woods have eyes...",
    type: null,
    drawnWinners: [
      {
        claimed: false,
        id: "FjXtEWPw6TaRnkacDNHmPYjwXKiCYWeMPTq5HtYYU9UX",
      },
      {
        claimed: false,
        id: "ATBWGLfvYVx7W2LH5Ybp2qMVqc6nSLVDm7xUYUv3xpWX",
      },
      {
        claimed: false,
        id: "5X9thmUni8YQbzBQR7c3bhQVxRkyQh3ryJs7WSu4yy93",
      },
      {
        claimed: false,
        id: "Dgr52d5KsAR8oMAKwNeKiHHoghfPgEWDmBSq7obaKKX3",
      },
      {
        claimed: false,
        id: "GzsKBF56kAkNgTuv4tXCFEEZ5ZjPEXTK7XyvwofJgBd5",
      },
    ],
  },
  {
    raffleObjectId: "6284ada089f01ba0effaa3dd",
    raffleId: "10",
    pricePerTicket: 20,
    receiverWallet: "TR1EJLFFzXAcWKhavvcxCjucVw13YDTgqyL2Y2bEbVe",
    tokenDecimal: 9,
    tokenMint: "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
    winners: 1,
    startDate: "2022-05-18T12:00:00+00:00",
    endDate: "2022-05-19T12:00:00+00:00",
    raffleName: "1x Great Goats WL",
    raffleImage:
      "https://cdn.discordapp.com/attachments/930171748350034040/976214269391687690/unknown.png",
    raffleDesc: "The greatest to do it.",
    drawnWinners: [
      {
        claimed: false,
        id: "2eji6aYhKUUXp7qgbSJFhHvkKPMHw9kxARTPHADtdPhZ - Choms#7469",
      },
    ],
    type: "whitelist",
  },
  {
    raffleObjectId: "62861b7771e7d3f253e657c1",
    raffleId: "11",
    pricePerTicket: 20,
    receiverWallet: "TR2D6Zk7QYqBFKYNdE4xDyyyeDhy87i2H2zRwy6FR6d",
    tokenDecimal: 9,
    tokenMint: "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
    winners: 5,
    startDate: "2022-05-19T12:00:00+00:00",
    endDate: "2022-05-20T12:00:00+00:00",
    raffleName: "5x BnH WL",
    raffleImage:
      "https://cdn.discordapp.com/attachments/952031276389511188/976455322271109150/peaky_blinder.png",
    raffleDesc:
      "7575 Bunnies are bouncing onto the Solana blockchain, and they're bringing their Homies with them.",
    soldTickets: 130,
    drawnWinners: [
      {
        claimed: false,
        id: "8xAkXNHaotnWKUtm9yhBG9vkuF66TqAWqoQEz4X1LbuJ - jie#8550",
      },
      {
        claimed: false,
        id: "Sg74ZoPTLFbiUdsnEyUn4V7wdu8ZUdsakkKm2BvHAx4 - kekkek",
      },
      {
        claimed: false,
        id: "EhAucfUHkSm5rLzRcnboWsH8rQKsDS5AZcv6sZFiK8Y5 - Newrich#8693",
      },
      {
        claimed: false,
        id: "3KKCGb6CoWjq4kJ8yAD4dq1tct5FW1KDXqrFyEFWvqMa - jeanclaude#3956",
      },
      {
        claimed: false,
        id: "2vFHrU4Dv5CbonYnf1QxduiHEeQ69vVZyXtDew5PxKxC - HotHam #8008",
      },
    ],
    type: "whitelist",
  },
  {
    raffleObjectId: "62894ab58c2028fde1b8b91d",
    raffleId: "12",
    pricePerTicket: 20,
    receiverWallet: "TR2D6Zk7QYqBFKYNdE4xDyyyeDhy87i2H2zRwy6FR6d",
    tokenDecimal: 9,
    tokenMint: "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
    winners: 5,
    startDate: "2022-05-21T22:00:00+00:00",
    endDate: "2022-05-22T22:00:00+00:00",
    raffleName: "5x Hello Pantha WL",
    raffleImage:
      "https://cdn.discordapp.com/attachments/952031276389511188/977582357311799296/Green.png",
    raffleDesc: "Moving slow like a Pantha",
    soldTickets: null,
    drawnWinners: [
      {
        claimed: false,
        id: "EuCA8AmrXj4Sk4rupdpHfhZ4q3cWoRx7w4Su7xr2GsxM - Huma#8868",
      },
      {
        claimed: false,
        id: "Fx9xU65UaCFa4BgXRaxKqZ1BEUu5mQD8V3LRrFHidkhN - Mango-#2634",
      },
      {
        claimed: false,
        id: "Ax3nafA7kC7W2TopsKNzE5vxqmNxSorvtc5Ji8SeXPUU - Jazzky#7235",
      },
      {
        claimed: false,
        id: "H5KVBuCCb9ACFqga7DmmWa89LFoMr1VL6w9v71RHiCKb - Charles102#5068",
      },
      {
        claimed: false,
        id: "34in6hsJsXkvjGdRg4WmpDviuDyLa8auKEM7SJ9KKaqN - senjinknight#2792",
      },
    ],
    type: "whitelist",
  },
  {
    raffleObjectId: "628b8b88c0827ba6e7900924",
    raffleId: "13",
    pricePerTicket: 50,
    receiverWallet: "TR3YJzPzJc31bZGqFzSN7eMJnqbvwCQUivbPA31kJou",
    tokenDecimal: 9,
    tokenMint: "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
    winners: 1,
    startDate: "2022-05-23T14:00:00+00:00",
    endDate: "2022-05-25T14:00:00+00:00",
    raffleName: "TS HS #2740",
    raffleImage:
      "https://arweave.net/8B5GR4WSnsjN80yVRihWv8OocNpgkL1oK_xX8WRXmQk",
    raffleDesc:
      "TombStoned High Society - A collection of 6,666 Stoned Skeletons rising into the metaverse in search of $JOINTS, Buds, and their $SOL.",
    soldTickets: null,
    drawnWinners: [
      {
        claimed: false,
        id: "BXD7nvsxMzDLyVvHruyPUKtFKq8BsnrhCBqwUteBwTg",
      },
    ],
    type: null,
  },
  {
    raffleObjectId: "628bfd6c5e738106b49ec5b8",
    raffleId: "14",
    pricePerTicket: 20,
    receiverWallet: "TR4rNS7uHTmMLgLw7Jjnn9k3sfooyasGYXChYtyPjsL",
    tokenDecimal: 9,
    tokenMint: "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
    winners: 2,
    startDate: "2022-05-24T00:00:00+00:00",
    endDate: "2022-05-24T16:00:00+00:00",
    raffleName: "2x KODS WL",
    raffleImage:
      "https://cdn.discordapp.com/attachments/952031276389511188/978328678545117265/12.png",
    raffleDesc: "Welcome to da Street 🏙️",
    soldTickets: null,
    drawnWinners: [
      {
        claimed: false,
        id: "BZi5J2Zy83wrzgEoQ41F7amcefAXcKfyaxzJR7UPZ762 - LanZa#4512 ",
      },
      {
        claimed: false,
        id: "7KPAhoWSydWdPKmacBBTXpcjZw9B82MVMTp8v8Q8eoKe - hammy555#8515",
      },
    ],
    type: "whitelist",
  },
  {
    raffleObjectId: "628d3a38511cf62f02d545e4",
    raffleId: "15",
    pricePerTicket: 20,
    receiverWallet: "TR5Ht6BaNY42u5hQhUy3rHQa3KV5hFvb783wLJostxJ",
    tokenDecimal: 9,
    tokenMint: "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
    winners: 5,
    startDate: "2022-05-24T21:00:00+00:00",
    endDate: "2022-05-25T21:00:00+00:00",
    raffleName: "5x Öneirans II WL",
    raffleImage:
      "https://cdn.discordapp.com/attachments/952031276389511188/978717829853499432/grave.png",
    raffleDesc: "Öneirans living in a deep dream",
    soldTickets: null,
    drawnWinners: [
      {
        claimed: false,
        id: "GhYSafdt73FijW6exFNy2wNgGyRb2rxiJ9kq5fMmWDMP - XLu_4AMan#7130",
      },
      {
        claimed: false,
        id: "64fEtTdkTvNKJvNHREJaABNHj1Gkk59FmX5zu96CZXNN - legacys01",
      },
      {
        claimed: false,
        id: "HKW1ijzUgxdsNvXytJ8q64j4YNksU4SqTkWwTx1hewn1 - Feng#5549",
      },
      {
        claimed: false,
        id: "ANtiFQdTrnJPP8MdG6GSNL1CZMrFG5xDjfDtw2LwZFaG - RR#2771",
      },
      {
        claimed: false,
        id: "BvZehrKLHUT594wYj21m6vh6mRDVkavo1NQKwbwxnfmi - Boruto#4056",
      },
    ],
    type: "whitelist",
  },
  {
    raffleObjectId: "628f43d788dbcdff9452e652",
    raffleId: "16",
    pricePerTicket: 50,
    receiverWallet: "TR6WhCxvNkyrnJnVLM5SUqxf23Y2NJPbSnWTvxPsHQo",
    tokenDecimal: 9,
    tokenMint: "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
    winners: 1,
    startDate: "2022-05-26T10:00:00+00:00",
    endDate: "2022-05-28T10:00:00+00:00",
    raffleName: "The Bridged #2036",
    raffleImage:
      "https://bafybeifwjc6zjpgw2527v53uoa3myps4sgxgywtdtdr25mflrje45354j4.ipfs.nftstorage.link/2048.png?ext=png",
    raffleDesc:
      "The Bridged are the second Faction within The Fracture. A 9999 collection of technologically enhanced humans who are at war with The Gods in The Fracture story.",
    soldTickets: null,
    type: null,
    drawnWinners: [
      {
        claimed: false,
        id: "Hcbh1KmoxeyyAzGKvKThVc4caDg4ZyjBoFX6okCnFbhS",
      },
    ],
  },
  {
    raffleObjectId: "62909f6845a911ca23f90b85",
    raffleId: "16",
    pricePerTicket: 20,
    receiverWallet: "TR7xSnBPmAdH54YeyWtrGYr7zjF557JDAJkRpEcLwPq",
    tokenDecimal: 9,
    tokenMint: "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
    winners: 3,
    startDate: "2022-05-27T12:00:00+00:00",
    endDate: "2022-05-28T12:00:00+00:00",
    raffleName: "3x Chill Dogs Club WL",
    raffleImage:
      "https://cdn.discordapp.com/attachments/952031276389511188/979516874297577562/IMG_1476.jpg",
    raffleDesc: "The Chillest Dogs on Solana",
    soldTickets: null,
    drawnWinners: [
      {
        claimed: false,
        id: "2WBfJ1takqMVZPupthJapirsh58yb48JWoQ5ctcZHsH7 - Brillinta#1588",
      },
      {
        claimed: false,
        id: "43SvQFjdbXuww6Y3Q7Qs7Gjqs5JfHUuax6ztEMkZqP3e - diamond_hand_god",
      },
      {
        claimed: false,
        id: "DTQEwoEVZwjkMhXQJY2ERZ5yccuZ6NNq3Li98dAa9JoA - crazyman#9421",
      },
    ],
    type: "whitelist",
  },
  {
    raffleObjectId: "62976a0867615bd00d9c3208",
    raffleId: "17",
    pricePerTicket: 50,
    receiverWallet: "TR8b7eF53G3UKhrXvxRhwND2YVWKZbMsZzt1NjdkQvK",
    tokenDecimal: 9,
    tokenMint: "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
    winners: 1,
    startDate: "2022-06-01T12:00:00+00:00",
    endDate: "2022-06-03T15:00:00+00:00",
    raffleName: "Space Robots #4343",
    raffleImage:
      "https://rgaibsgccksqt2kzeacry2jew4ymkvucwfr5akfflrruf732co6q.arweave.net/iYCAyMISpQnpWSAFHGkktzDFVoKxY9AopVxjQv96E70?ext=png",
    raffleDesc: "5555 Space Robots living on the Solana blockchain",
    soldTickets: null,
    drawnWinners: [
      {
        claimed: false,
        id: "A835Xf7MtaQqwpiKqLBhkPs3Lf2tR2j56S5BCjvJQqhE",
      },
    ],
    type: null,
  },
  {
    raffleObjectId: "62976cdb640909cb8a61315b",
    raffleId: "18",
    pricePerTicket: 50,
    receiverWallet: "TR9Tm5jYhbHwWb7UH72sXH376QREiS5iqgw4Y6aWJdX",
    tokenDecimal: 9,
    tokenMint: "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
    winners: 1,
    startDate: "2022-06-01T12:00:00+00:00",
    endDate: "2022-06-04T15:00:00+00:00",
    raffleName: "Zenjin Viperz #1849",
    raffleImage:
      "https://bafybeicsbdb3oc3fdh224pj62ur23i6jo4gfztjdreyc445vob4lokg7sy.ipfs.nftstorage.link/",
    raffleDesc:
      "Zenjin Viperz are an exclusive collection of 2,000 NFTs on the Solana Blockchain.",
    soldTickets: null,
    drawnWinners: [
      {
        claimed: false,
        id: "3NiidKWHhLvZRVSHiUcyXHNiQ7MvxS66TnvW3rNtwsYG",
      },
    ],
    type: null,
  },
  {
    raffleObjectId: "629f16d78372007d48f6fdb3",
    raffleId: "19",
    pricePerTicket: 20,
    receiverWallet: "TR9Tm5jYhbHwWb7UH72sXH376QREiS5iqgw4Y6aWJdX",
    tokenDecimal: 9,
    tokenMint: "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
    winners: 2,
    startDate: "2022-06-07T12:00:00+00:00",
    endDate: "2022-06-08T12:00:00+00:00",
    raffleName: "2x Gakko Monkeys WL",
    raffleImage:
      "https://cdn.discordapp.com/attachments/942898445558087680/979756452539400192/EC090975-77A9-4359-B70E-869861216446.jpg",
    raffleDesc: "Welcome to the Gakkoon! A place like no other seen before...",
    soldTickets: null,
    drawnWinners: [
      {
        claimed: false,
        id: "Hcbh1KmoxeyyAzGKvKThVc4caDg4ZyjBoFX6okCnFbhS - Burki#1907",
      },
      {
        claimed: false,
        id: "3NiidKWHhLvZRVSHiUcyXHNiQ7MvxS66TnvW3rNtwCeZL5hWnjCF5ZVzBS91KnhCNax1wDG3wpGcT8nowi8fT - EDWIN◎#1113sYG",
      },
    ],
    type: "whitelist",
  },
  {
    raffleObjectId: "629f4feed8ce9932613c50c1",
    raffleId: "20",
    pricePerTicket: 50,
    receiverWallet: "TR9Tm5jYhbHwWb7UH72sXH376QREiS5iqgw4Y6aWJdX",
    tokenDecimal: 9,
    tokenMint: "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
    winners: 1,
    startDate: "2022-06-07T12:00:00+00:00",
    endDate: "2022-06-09T15:00:00+00:00",
    raffleName: "Solful #898",
    raffleImage:
      "https://img-cdn.magiceden.dev/rs:fill:640:640:0:0/plain/https://bafybeig2vzxgrcmhiqtx2ne52rl44pnrczarvcdtm3ldnyhpz6oxollvqe.ipfs.dweb.link/897.png?ext=png",
    raffleDesc: "Solful creatures wandering around the peaceful forest.",
    soldTickets: null,
    drawnWinners: [
      {
        claimed: false,
        id: "88eHgZn6gB2fVaM3HmyJg44hy9HBdBKZPTctTt47wLbg",
      },
    ],
    type: null,
  },
  {
    raffleObjectId: "62a3032f779bf2c376b2252b",
    raffleId: "21",
    pricePerTicket: 50,
    receiverWallet: "TR9Tm5jYhbHwWb7UH72sXH376QREiS5iqgw4Y6aWJdX",
    tokenDecimal: 9,
    tokenMint: "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
    winners: 1,
    startDate: "2022-06-09T22:00:00+00:00",
    endDate: "2022-06-11T22:00:00+00:00",
    raffleName: "Vandal #8367",
    raffleImage:
      "https://bafybeifwppiwoho2374wo62ozzxsl6ce54jzgkhxg2rnu7mc7vqafht3cq.ipfs.nftstorage.link/8367.png?ext=png",
    raffleDesc:
      "Vandal City is a lifestyle brand and blockchain gaming project founded by a talented team of blockchain engineers, artists, and web3 enthusiasts fostering community, utility, and art",
    soldTickets: null,
    drawnWinners: [
      {
        claimed: false,
        id: "F6HLkRnxsuiuSXZbpa26MwRDWmXp5s3ZDtkQy3bG7Hvd",
      },
    ],
    type: null,
  },
  {
    raffleObjectId: "62a73fc2ea34c98a5875c915",
    raffleId: "22",
    pricePerTicket: 50,
    receiverWallet: "TR9Tm5jYhbHwWb7UH72sXH376QREiS5iqgw4Y6aWJdX",
    tokenDecimal: 9,
    tokenMint: "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
    winners: 1,
    startDate: "2022-06-13T15:00:00+00:00",
    endDate: "2022-06-15T15:00:00+00:00",
    raffleName: "Great Goats #1816",
    raffleImage:
      "https://bafybeiawf4hshe54c2lbdtu6cb7vbuztpxgpo2yj57gd2stxfhzkuke7le.ipfs.nftstorage.link/1816.png?ext=png",
    raffleDesc:
      "Be a Goat. Where your identity matters. A label for the ecosystem. Followed by the community.",
    soldTickets: null,
    drawnWinners: [
      {
        claimed: false,
        id: "3GWChrWxtTqHwfJR81NK3TzTqnBSmyjbdsodRbs3HzKs",
      },
    ],
    type: null,
  },
  {
    raffleObjectId: "62a73fbe03682dced31d287c",
    raffleId: "23",
    pricePerTicket: 50,
    receiverWallet: "TR9Tm5jYhbHwWb7UH72sXH376QREiS5iqgw4Y6aWJdX",
    tokenDecimal: 9,
    tokenMint: "C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6",
    winners: 1,
    startDate: "2022-06-13T15:00:00+00:00",
    endDate: "2022-06-15T15:00:00+00:00",
    raffleName: "Fat Cats #2323",
    raffleImage:
      "https://bafybeibo5dg3m6evn6vcztmkanxpv2ywqemqbxzao34glvzvni44w77tv4.ipfs.nftstorage.link/2323.png?ext=png",
    raffleDesc:
      "The FatCats have awoken from their cat nap and created a community that will advance the Solana ecosystem like no other.",
    soldTickets: null,
    drawnWinners: [
      {
        claimed: false,
        id: "5QGrQ3JpPMTP7JuuWQfuQYx1epWfPVnk41hXaMcxZpLV",
      },
    ],
    type: null,
  },
];

export const tx_transfer_spl = async (
  conn: Connection,
  from: PublicKey,
  to: PublicKey,
  token: PublicKey,
  amount: number,
  decimals: number,
  sendTransaction: Function
) => {
  const source = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    token,
    from
  );

  if ((await conn.getParsedAccountInfo(from)).value == null) {
    console.error("can't debit from an account that doesn't exist");
    return;
  }

  const dest = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    token,
    to
  );

  const txs = new Transaction();

  if ((await conn.getParsedAccountInfo(dest)).value == null) {
    txs.add(
      Token.createAssociatedTokenAccountInstruction(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        token,
        dest,
        to,
        from
      )
    );
  }

  txs.add(
    Token.createTransferCheckedInstruction(
      TOKEN_PROGRAM_ID,
      source,
      token,
      dest,
      from,
      [],
      amount * Math.pow(10, decimals),
      decimals
    )
  );

  return txs;
};

export const buyTickets = async (
  raffle: any,
  n_tickets: number,
  connection: any,
  publicKey: PublicKey,
  sendTransaction: Function,
  discordId?: string
) => {
  const txs = new Transaction();

  const amount = n_tickets * raffle.pricePerTicket;

  const transferIx = await tx_transfer_spl(
    connection,
    publicKey!,
    new PublicKey(raffle.receiverWallet),
    new PublicKey(raffle.tokenMint),
    amount,
    raffle.tokenDecimal,
    sendTransaction
  );

  txs.add(
    new TransactionInstruction({
      keys: [{ pubkey: publicKey!, isSigner: true, isWritable: true }],
      data: Buffer.from(
        `Raffle #${raffle.raffleId} - ${amount / raffle.pricePerTicket}`,
        "utf-8"
      ),
      programId: new PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
    })
  );

  discordId &&
    txs.add(
      new TransactionInstruction({
        keys: [{ pubkey: publicKey!, isSigner: true, isWritable: true }],
        data: Buffer.from(
          `${discordId ? `Discord ID - ${discordId}` : ""}`,
          "utf-8"
        ),
        programId: new PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
      })
    );

  if (transferIx === null || transferIx === undefined) {
    return;
  }

  txs.add(transferIx);

  try {
    let blockhashObj = await connection.getRecentBlockhash();
    txs.recentBlockhash = blockhashObj.blockhash;
    txs.feePayer = publicKey!;

    const txid = await sendTransaction(txs, connection);

    let done = false;
    let status = {
      slot: 0,
      confirmations: 0,
      err: null,
    };
    // let subId = 0;
    status = await new Promise(async (resolve, reject) => {
      setTimeout(() => {
        if (done) {
          return;
        }
        done = true;
        console.log("Rejecting for timeout...");
        reject({ timeout: true });
      }, 120000);
      while (!done) {
        // eslint-disable-next-line no-loop-func
        (async () => {
          try {
            const signatureStatuses = await connection.getSignatureStatuses([
              txid,
            ]);
            status = signatureStatuses && signatureStatuses.value[0];
            if (!done) {
              if (!status) {
                console.log("REST null result for", txid, status);
              } else if (status.err) {
                console.log("REST error for", txid, status);
                done = true;
                reject(status.err);
              } else if (!status.confirmations) {
                console.log("REST no confirmations for", txid, status);
              } else {
                console.log("REST confirmation for", txid, status);
                done = true;
                resolve(status);
              }
            }
          } catch (e) {
            if (!done) {
              console.log("REST connection error: txid", txid, e);
            }
          }
        })();
        await sleep(2000);
      }
    });

    done = true;
    console.log("Returning status", status);
    return status;
  } catch (e) {
    console.error(e);
  }
};

const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
