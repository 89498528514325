import RaffleItem from "components/RaffleItem";
import { raffleDefaults } from "helpers/functions";
import styled from "styled-components";

const RaffleHome = () => {
  return (
    <Container>
      {raffleDefaults
        .sort((a, b) => (a.endDate < b.endDate ? 1 : -1))
        .map((raffle) => (
          <RaffleItem raffle={raffle} key={raffle.raffleId} />
        ))}
    </Container>
  );
};

export default RaffleHome;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    align-items: center;

    flex-direction: column;
  }
`;
