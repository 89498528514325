import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import dayjs from "dayjs";
import { buyTickets, raffleDefaults } from "helpers/functions";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import NumberPicker from "react-widgets/cjs/NumberPicker";
import styled from "styled-components";
import { Form } from "react-bootstrap";

export const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID: PublicKey = new PublicKey(
  "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
);

const RaffleDetails = ({ match }: { match: any }) => {
  const raffle = raffleDefaults.find((element) => {
    return element.raffleObjectId === match.params.id;
  });

  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();

  const [value, setValue] = useState(1);
  const [discordId, setDiscordId] = useState("");
  const [userTickets, setUserTickets] = useState(null);
  const [totalTickets, setTotalTickets] = useState(null);
  const date1 = dayjs(raffle.endDate);
  const endDate = date1.diff(new Date(), "second");

  const ended = date1.diff(new Date(), "second") < 0;

  async function refreshAll() {
    await getTotalTickets();
    await getUserTickets();
  }

  useEffect(() => {
    refreshAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey]);

  const getTotalTickets = async () => {
    const test = await PublicKey.findProgramAddress(
      [
        new PublicKey(raffle.receiverWallet).toBuffer(),
        TOKEN_PROGRAM_ID.toBuffer(),
        new PublicKey(raffle.tokenMint).toBuffer(),
      ],
      SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
    );

    if (test) {
      const sigs = await connection.getSignaturesForAddress(
        new PublicKey(test[0]?.toString()),
        {
          limit: 1000,
        }
      );

      const result = sigs.reduce(
        (total, currentValue) =>
          (total =
            total +
            Number(
              currentValue && raffle.raffleId.length > 1
                ? currentValue.memo.substring(13, 15) === raffle.raffleId
                  ? raffle.type === "whitelist"
                    ? currentValue.memo
                        .substring(
                          currentValue.memo.indexOf(""),
                          currentValue.memo.lastIndexOf(";")
                        )
                        .substring(18)
                    : currentValue.memo.substring(18)
                  : 0
                : currentValue.memo.substring(13, 14) === raffle.raffleId
                ? raffle.type === "whitelist"
                  ? currentValue.memo
                      .substring(
                        currentValue.memo.indexOf(""),
                        currentValue.memo.lastIndexOf(";")
                      )
                      .substring(17)
                  : currentValue.memo.substring(17)
                : 0
            )),
        0
      );
      setTotalTickets(result);
    }
  };

  const getUserTickets = async () => {
    if (publicKey) {
      const test = await PublicKey.findProgramAddress(
        [
          publicKey?.toBuffer(),
          TOKEN_PROGRAM_ID.toBuffer(),
          new PublicKey(raffle.tokenMint).toBuffer(),
        ],
        SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
      );

      if (test) {
        const sigs = await connection.getSignaturesForAddress(
          new PublicKey(test[0]?.toString()),
          {
            limit: 1000,
          }
        );

        const result = sigs.reduce(
          (total, currentValue) =>
            (total =
              total +
              Number(
                currentValue && raffle.raffleId.length > 1
                  ? currentValue.memo?.substring(13, 15) === raffle.raffleId
                    ? raffle.type === "whitelist"
                      ? currentValue.memo
                          .substring(
                            currentValue.memo.indexOf(""),
                            currentValue.memo.lastIndexOf(";")
                          )
                          .substring(18)
                      : currentValue.memo.substring(18)
                    : 0
                  : currentValue.memo.substring(13, 14) === raffle.raffleId
                  ? raffle.type === "whitelist"
                    ? currentValue.memo
                        .substring(
                          currentValue.memo.indexOf(""),
                          currentValue.memo.lastIndexOf(";")
                        )
                        .substring(17)
                    : currentValue.memo.substring(17)
                  : 0
              )),
          0
        );
        setUserTickets(result);
      }
    }
  };
  const raffleAction = async () => {
    if (publicKey === null) {
      toast.error("Please connect your wallet.");
    } else if (raffle.type === "whitelist" && discordId === "") {
      toast.error("Please enter your Discord ID");
    } else {
      // setLoading(true);
      const id = toast.loading("Processing Transaction...");
      const res = await buyTickets(
        raffle,
        value,
        connection,
        publicKey,
        sendTransaction,
        raffle.type === "whitelist" && discordId
      );

      if (res?.err === null) {
        await toast.success("Ticket Purchased.", { id });
        refreshAll();
      } else {
        toast.error("Something went wrong.", { id });
      }
    }
  };

  return (
    <RaffleDetailsContainer>
      <div>
        <RaffDetailsImg src={raffle.raffleImage} />
      </div>
      <div>
        <RaffleDetailsInfoHeader>
          <RaffleDetailsInfoTitle>
            <h3>{raffle.raffleName}</h3>
          </RaffleDetailsInfoTitle>
          {/* <div>
            <a
              href="https://solscan.io/token/8T2gYeLZQPCNV49mzUMWResTLLLmrymgueiyehcQF3s"
              target={"_blank"}
              rel="noreferrer"
            >
              View Token
            </a>
          </div> */}
        </RaffleDetailsInfoHeader>
        <RaffleDetailsDesc>
          <p>{raffle.raffleDesc}</p>
        </RaffleDetailsDesc>
        <RaffleDetailsInfoStats>
          <RaffleDetailsInfoStat>
            {!ended ? (
              <>
                <span>Ends in</span>
                <p>
                  {endDate > 86400
                    ? `${(endDate / 60 / 60 / 24).toFixed(0)} days`
                    : endDate > 3600
                    ? `${(endDate / 60 / 60).toFixed(0)} hrs`
                    : endDate < 3600
                    ? `${(endDate / 60).toFixed(0)} mins`
                    : `${endDate} seconds`}
                </p>
              </>
            ) : (
              <span>Raffle closed!</span>
            )}
          </RaffleDetailsInfoStat>
          <RaffleDetailsInfoStat>
            <span>Sold Tickets</span>
            <p>{totalTickets ?? 0}</p>
          </RaffleDetailsInfoStat>
          <RaffleDetailsInfoStat>
            <span>Winners</span>
            <p>{raffle.winners}</p>
          </RaffleDetailsInfoStat>
        </RaffleDetailsInfoStats>
        {!ended ? (
          <RaffleDetailsInfoBuy>
            <p>Price: {raffle.pricePerTicket} $T1NY</p>
            <RaffleDetailsButtonContainer>
              <RaffleDetailsPickerContainer>
                <NumberPicker
                  defaultValue={1}
                  min={1}
                  value={value}
                  onChange={(value) => setValue(value)}
                />
              </RaffleDetailsPickerContainer>
              {raffle.type === "whitelist" && (
                <Form.Group
                  controlId="formBasicEmail"
                  style={{ margin: 0, maxWidth: 200, marginRight: 24 }}
                >
                  <Form.Control
                    type="text"
                    placeholder="Enter Discord ID"
                    onChange={(e) => setDiscordId(e.target.value)}
                    defaultValue={discordId}
                  />
                </Form.Group>
              )}
              <button className="button" onClick={() => raffleAction()}>
                Buy Tickets
              </button>
            </RaffleDetailsButtonContainer>
            {userTickets > 0 && (
              <p
                style={{
                  fontSize: 16,
                  color: "color: rgb(31, 65, 112)",
                  fontWeight: 500,
                  marginBottom: 0,
                  marginTop: 18,
                  opacity: 0.75,
                }}
              >
                You have purchased {userTickets ?? 0} tickets!
              </p>
            )}
          </RaffleDetailsInfoBuy>
        ) : (
          <RaffleDetailsInfoBuy>
            <p>Raffle closed!</p>
            <p>🎉 Congrats to the winners 🎉</p>
            <ul>
              {raffle.drawnWinners?.map((winner) => (
                <li>{winner.id}</li>
              ))}
            </ul>
          </RaffleDetailsInfoBuy>
        )}
      </div>
    </RaffleDetailsContainer>
  );
};

export default RaffleDetails;

const RaffleDetailsContainer = styled.div`
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  border: 4px solid rgb(225, 167, 202);
  margin: 0 auto;
  max-width: 1000px;
  padding: 16px;
  text-align: center;
  width: 100%;

  @media only screen and (min-width: 768px) {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: 250px 1fr;
    text-align: left;
  }
`;

const RaffDetailsImg = styled.img`
  /* border-radius: 16px; */
  height: 250px;
  margin-bottom: 32px;
  object-fit: cover;
  width: 250px;
`;

const RaffleDetailsDesc = styled.div`
  h3 {
    font-family: "Open Sans";
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  p {
    color: rgb(31, 65, 112);
    font-family: "Work Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3;
  }
`;

const RaffleDetailsInfoHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  a {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    text-decoration: underline;
  }
`;

const RaffleDetailsInfoTitle = styled.div`
  align-items: center;
  display: flex;

  h3 {
    color: rgb(31, 65, 112);
    font-family: "Open Sans";
    font-size: 32px;
    font-weight: 600;
    margin: 0;
  }

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-left: 16px;
    }
  }
`;

const RaffleDetailsInfoStats = styled.div`
  display: flex;
  margin-bottom: 16px;
  text-align: center;

  @media only screen and (max-width: 768px) {
    justify-content: space-around;
  }

  @media only screen and (min-width: 768px) {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(3, 150px);
    text-align: left;
  }
`;

const RaffleDetailsInfoStat = styled.div`
  color: rgb(31, 65, 112);
  display: flex;
  flex-direction: column;
  height: 120px;
  justify-content: center;

  span {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin: 0px;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  p {
    font-size: 32px;
    font-weight: 500;
    margin: 0;
  }
`;

const RaffleDetailsInfoBuy = styled.div`
  color: rgb(31, 65, 112);
  display: flex;
  flex-direction: column;
  /* height: 200px; */
  justify-content: center;
  padding: 24px 0;
  /* text-align: center; */

  p {
    /* color: #ffffff; */
    font-family: "Open Sans";
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  span {
    /* color: #94f423; */
  }
`;

const RaffleDetailsButtonContainer = styled.div`
  align-items: center;
  display: flex;
  /* justify-content: center; */
  margin-top: 12px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;

    .button {
      margin-top: 10px;
    }
  }

  .button {
    background: rgb(75, 236, 228) none repeat scroll 0% 0%;
    border: 0px none;
    border-radius: 4px;
    box-shadow: rgb(63, 120, 125) 2px 3px 0px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-size: 24px;
    font-weight: 900;
    height: 60px;
    padding-left: 24px;
    padding-right: 24px;
    text-transform: uppercase;

    &:hover {
      background: rgb(56, 177, 171) none repeat scroll 0% 0%;
    }
  }
`;

const RaffleDetailsPickerContainer = styled.div`
  max-width: 200px;

  @media only screen and (min-width: 768px) {
    margin-right: 24px;
    max-width: 120px;
  }
`;
