import dayjs from "dayjs";
import { Link } from "react-router-dom";
import styled from "styled-components";

const RaffleItem = ({ raffle }: { raffle: any }) => {
  const date1 = dayjs(raffle.endDate);
  const endDate = date1.diff(new Date(), "second");
  const ended = date1.diff(new Date(), "second") < 0;

  return (
    <RaffleItemContainer>
      <RaffleItemImage src={raffle.raffleImage} />
      <RaffleItemDetails>
        <h3>{raffle.raffleName}</h3>
        <RaffleItemDetailsGrid>
          <p>{raffle.pricePerTicket} $T1NY</p>
          {!ended ? (
            <p>
              {endDate > 86400
                ? `${(endDate / 60 / 60 / 24).toFixed(0)} ${
                    (endDate / 60 / 60 / 24).toFixed(0) === "1" ? "day" : "days"
                  }`
                : endDate > 3600
                ? `${(endDate / 60 / 60).toFixed(0)} ${
                    (endDate / 60 / 60).toFixed(0) === "1" ? "hr" : "hrs"
                  }`
                : endDate < 3600
                ? `${(endDate / 60).toFixed(0)} mins`
                : `${endDate} seconds`}
            </p>
          ) : (
            <p>Closed!</p>
          )}
        </RaffleItemDetailsGrid>

        {!ended ? (
          <Link to={`/raffle/${raffle.raffleObjectId}`}>Join Raffle</Link>
        ) : (
          <Link className="winners" to={`/raffle/${raffle.raffleObjectId}`}>
            View Winners
          </Link>
        )}
      </RaffleItemDetails>
    </RaffleItemContainer>
  );
};

export default RaffleItem;

const RaffleItemContainer = styled.div`
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  border: 4px solid rgb(225, 167, 202);
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  overflow: hidden;
  width: 250px;
`;

const RaffleItemImage = styled.img`
  height: 250px;
  object-fit: cover;
  width: 250px;
`;

const RaffleItemDetails = styled.div`
  min-height: 150px;
  padding: 16px;
  position: relative;
  text-align: center;

  h3 {
    color: #1a202c;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
  }

  p {
    color: #1a202c;
    font-family: "Work Sans";
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    opacity: 0.75;
    margin-bottom: 8px;
  }

  a {
    align-items: center;
    background: transparent;
    border: 2px solid #94f423;
    border-radius: 8px;
    bottom: 16px;
    color: #94f423;
    display: flex;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    justify-content: center;
    left: 16px;
    min-height: 40px;
    position: absolute;
    right: 16px;

    &:hover {
      background: #94f423;
      color: #2d2c53;
      text-decoration: none;
    }

    background: rgb(75, 236, 228) none repeat scroll 0% 0%;
    border: 0px none;
    border-radius: 4px;
    box-shadow: rgb(63, 120, 125) 2px 3px 0px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-size: 18px;
    font-weight: 900;
    /* height: 60px; */
    text-transform: uppercase;
    /* width: 300px; */

    &:hover {
      background: rgb(56, 177, 171) none repeat scroll 0% 0%;
      color: rgb(255, 255, 255);
    }

    &.winners {
      background: #6c6b87;
      box-shadow: #343342 2px 3px 0px;
      border: 2px solid #6c6b87;
      color: #fff;
    }
  }
`;

const RaffleItemDetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
